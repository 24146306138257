.earnp2p {
    position: relative;
    padding-bottom: 400px;

    &__title {
        color: #FFF;
        font-family: 'Impact', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 39px;
        margin-bottom: 80px;

    }

    &__content {}

    &__part {

        padding: 15px 60px 15px 40px;
        border-radius: 10px;
        border: 1px solid #617FF5;
    }

    &__partTitle {
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin-left: 26px;
        margin-bottom: 20px;

    }

    &__rowDesc {
        margin-bottom: 20px;
        & svg {
            width: 38px;
            margin-right: 10px;
        }
    }

    &__desc {
        max-width: 379px;
       
        color: #FFF;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


@media (max-width: 900px) {
    .earnp2p {
        &__title {
            text-align: center;
        }

        &__content {
            flex-direction: column;
        }

        &__part {
            margin-bottom: 30px;
        }
    }
}



@media (max-width: 500px) {
    .earnp2p {
        padding-bottom: 150px;
    }
}


@media (max-width: 450px) {
    .earnp2p {
        &__part {
            padding: 15px 15px;
        }
        &__desc {
            max-width: 260px;
        }
        &__rowDesc {
         
            & svg {
              
            
            }
        }
    }
}

@media (max-width: 350px) {
    .earnp2p {
        &__part {
            padding: 10px 5px;
        }
        &__desc {
            max-width: 200px;
        }
        &__rowDesc {
         
            & svg {
              margin-right: 5px;
            
            }
        }
    }
}