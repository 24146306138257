.consultation {
    & .container {
        z-index: 100;
    }

    padding-top: 280px;
    background-color: black;

    &__image {
        width: 484px;
        height: 305px;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__title {
        color: var(--neutral-white, #FFF);
        font-family: 'Montserrat', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;

    }

    &__desc {
        color: var(--neutral-grey-5, #E0E0E0);
        font-family: 'Montserrat', sans-serif;
        font-size: 17px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0.17px;
        z-index: 100;
    }

    &__left2 {
        display: none;
    }

    & .about {
        position: relative;
        z-index: 100;
        max-width: 1500px;
        margin: 136px auto 0;


        &__desc {
            color: #968E8E;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 19px;
        }
    }
}

@media (max-width: 950px) {
    .consultation {
        &__content {
            grid-template-columns: 1fr;
            row-gap: 40px;
        }

        &__left {
            display: none;

        }

        &__title {
            text-align: center;
            margin-bottom: 30px;
        }

        &__desc {
            text-align: center;

        }

        &__left2 {
            display: flex;
            justify-content: center;
        }
    }
}


@media (max-width: 700px) {
    .consultation {
       
        &__about {
            grid-template-columns: 1fr;
        }

        & .about1 {
            justify-content: center;
            max-width: 500px;
            margin: 0 auto;
        }
    }
}

@media (max-width: 500px) {
    .consultation {
        padding-top: 50px;
        &__title {
            font-size: 33px;
        }
        &__desc {
            font-size: 16px;
        }
        &__image {
            width: 60%;
            height: 60%;

            & img {
                width: 100%;
                height: auto;
                ;
            }
        }
        & .about {
            &__desc {
                font-size: 15px;
            }
        }

        & .about1 {
            justify-content: center;
            max-width: 360px;
            margin: 0 auto;
        }
    }
}

@media (max-width: 450px){
    .consultation {
        &__about {
            padding: 0 10px;
        }
    }
}

@media (max-width: 350px) {

    .consultation .about1 {
        justify-content: center;
        max-width: 275px;
        margin: 0 auto 30px;
    }

    .consultation {
        &__title {
            max-width: 350px;
            font-size: 33px;
        }
    }
}

@media (max-width: 300px) {


    .consultation {
        &__about {
            padding: 10px;
        }
        &__title {
          
            font-size: 26px;
        }
    }
}







