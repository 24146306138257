.footer {
    padding: 80px 0;
    background: var(--color-primary, #0D0D2B);

    & .align-between {
        height: 130%;
    }

    & .container {
        max-width: 1360px;
    }

    &__grid {
      row-gap: 50px;
    }

    &__title {
        color: var(--neutral-white, #FFF);
        font-family: 'Rubik', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 24px;
    }

    &__title-lg {
        color: var(--neutral-white, #FFF);
        font-family: 'Rubik', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;

    }

    &__link {
        color: var(--neutral-grey-6, #F2F2F2);
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
    }

    &__link:hover {
        color: rgb(196, 195, 195);
        transition: 0.3s;
    }

    &__icons {
        margin-top: 40px;
    }

    &__icon {
        cursor: pointer;
        color: #FFF;
        font-size: 25px;
        margin-right: 30px;
    }

    &__icon:hover {
        transition: 0.3s;
        color: rgb(196, 195, 195);
    }

}


@media (max-width: 700px) {
    .footer {
        & .container {
            padding: 20px;
        }

        &__grid {
            grid-template-columns: 1fr 1fr;
            row-gap: 50px;
        }
    }
}


@media (max-width: 450px) {
    .footer {

        &__grid {
            grid-template-columns: 1fr;

        }
    }
}




@media (max-width: 1020px) {
    .footer {
        &__icon {
            margin-bottom: 20px;
        }
    }
}