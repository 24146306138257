.clubhero {
    position: relative;
    background-color: black;
    overflow: hidden;
    padding-bottom: 60px;
    & .container {
        position: relative;
    }

    &__title {
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 175px 0 30px;
    }

    &__desc {
        color: #CFCFCF;
        text-align: center;
        font-family: 'Outfit', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        text-align: center;

    }

    &__title-sm {
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        margin: 100px 0 90px;
    }

    &__grid {
        row-gap: 100px;
        column-gap: 50px;
    }

    & .part {
        &__row {
            margin-bottom: 30px;
        }

        &__title {
            position: relative;
            color: var(--white, #FFF);
            font-family: 'Montserrat', sans-serif;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 20px;
        }

        &__desc {
            color: var(--white, #FFF);
            font-family: 'Rubik', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            opacity: 0.6;

        }

        &__divforsvg {
            width: 65px;
            height: 65px;
        }
    }
}


.circle__right {
    position: absolute;
    top: 0;
    right: 0;
}

.circle__left2 {
    position: absolute;
    width: 900px;
    height: 700px;
    left: 0;
    top: 65rem;
  

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.6;
    }
}


@media (max-width: 600px) {
    .clubhero {
        &__title {
            font-size: 38px;
        }
        &__desc {
            font-size: 20px;
        }
        
        &__title-sm {
            font-size: 35px;
        }
      
}
}