.questions {
    position: relative;
    padding-bottom: 500px;

    & .container {
        position: relative;
        max-width: 1500px;
    }

    padding-top: 200px;

    &__list {
      column-gap: 10px;
    }

    &__title {

        color: #FFF;
        font-family: 'Impact', sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        flex-shrink: 0;
    }

    &__titleCover {
        padding: 47px 150px 67px 70px;
        width: 800px;
        clipPath: polygon(0 1%, 100% 0%, 95% 100%, 0% 100%);
        border-radius: 20px;
        background: linear-gradient(180deg, #526DF9 0%, rgba(145, 162, 250, 0.00) 100%);
    }

    & .list {

        margin-left: -60px;

        &__content {
            align-items: center;

        }

        &__image {
            margin-left: -40px;
            position: relative;
            display: flex;
            align-items: center;

            & img {

                width: 100%;
                height: 85%;

            }
        }




        &__desc {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            height: 100px;
            margin: auto;
            width: 100px;
            color: #030C0D;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        &__desc3 {
            height: 193px;
            width: 107px;
        }

        &__desc4 {
            height: 142px;
            width: 138px;
        }
    }
}

.background {
    position: absolute;
    left: 0;
    right: 0;
    top: -80px;
    margin: 0 auto;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.6;
    }
}

.question__leftCircle {
    position: absolute;
    left: 0;
    top: -100rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0.6;
    }
}



@media (max-width: 950px) {
    .questions {
        &__content {
            flex-direction: column;
            align-items: center;
        }
        & .list {
        
            grid-template-columns: 1fr 1fr;
            &__image {
                width: 120%;
                
            }
        }
    }

    
    .questions .list__image {
        margin: 0 auto;
    }
}

@media (max-width: 950px) {
  .questions {
    &__titleCover {
        background: none;
        text-align: center;
        padding: 20px;
        width: auto;
       
    }
  }
}

@media (max-width: 550px) {
    .questions {
        padding-bottom: 150px;
        & .list {
            margin: 0;
            width: 56%;
            grid-template-columns: 1fr;
            row-gap: 30px;
        }
    }
}


@media (max-width: 450px) {
    .questions {
       &__title {
        font-size: 31px;
       }
       & .list {
       &__image {
        height: 160%;
       }
       }
    }
}

@media (max-width: 300px) {
    .questions {
   
       & .list {
       &__image {
        height: 180%;
       }
       }
    }
}


