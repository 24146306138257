@font-face {
    font-family: '../fonts/Roboto';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Russo One';
    src: url('../fonts/RussoOne-Regular.eot');
    src: url('../fonts/RussoOne-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RussoOne-Regular.woff2') format('woff2'),
        url('../fonts/RussoOne-Regular.woff') format('woff'),
        url('../fonts/RussoOne-Regular.ttf') format('truetype'),
        url('../fonts/RussoOne-Regular.svg#RussoOne-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cinzel';
    src: url('../fonts/Cinzel-Regular.eot');
    src: url('../fonts/Cinzel-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Cinzel-Regular.woff2') format('woff2'),
        url('../fonts/Cinzel-Regular.woff') format('woff'),
        url('../fonts/Cinzel-Regular.ttf') format('truetype'),
        url('../fonts/Cinzel-Regular.svg#Cinzel-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-SemiBold.eot');
    src: url('../fonts/Outfit-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Outfit-SemiBold.woff2') format('woff2'),
        url('../fonts/Outfit-SemiBold.woff') format('woff'),
        url('../fonts/Outfit-SemiBold.ttf') format('truetype'),
        url('../fonts/Outfit-SemiBold.svg#Outfit-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-Regular.eot');
    src: url('../fonts/Outfit-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Outfit-Regular.woff2') format('woff2'),
        url('../fonts/Outfit-Regular.woff') format('woff'),
        url('../fonts/Outfit-Regular.ttf') format('truetype'),
        url('../fonts/Outfit-Regular.svg#Outfit-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.eot');
    src: url('../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff'),
        url('../fonts/Inter-Regular.ttf') format('truetype'),
        url('../fonts/Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Medium.eot');
    src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.eot');
    src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-SemiBold.eot');
    src: url('../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Medium.eot');
    src: url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Medium.woff2') format('woff2'),
        url('../fonts/Rubik-Medium.woff') format('woff'),
        url('../fonts/Rubik-Medium.ttf') format('truetype'),
        url('../fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Regular.eot');
    src: url('../fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Regular.woff2') format('woff2'),
        url('../fonts/Rubik-Regular.woff') format('woff'),
        url('../fonts/Rubik-Regular.ttf') format('truetype'),
        url('../fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-SemiBold.eot');
    src: url('../fonts/Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-SemiBold.woff2') format('woff2'),
        url('../fonts/Rubik-SemiBold.woff') format('woff'),
        url('../fonts/Rubik-SemiBold.ttf') format('truetype'),
        url('../fonts/Rubik-SemiBold.svg#Rubik-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('truetype'),
        url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBold.eot');
    src: url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('../fonts/Montserrat-ExtraBold.ttf') format('truetype'),
        url('../fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat-Medium.ttf') format('truetype'),
        url('../fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
        url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

