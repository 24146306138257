.header__nav {
    color: black;
}



.header {
    position: absolute;
    width: 100%;

    top: 0;
    margin: 0 auto;


    height: 100px;


    &__link {
        cursor: pointer;
        color: var(--neutral-white, #FFF);
        font-family: Impact;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.16px;
    }
    &__link:hover {
        color:  rgb(196, 195, 195);
        cursor: pointer;
        transition: 0.3s;
    }

    &__link:not(:last-child) {
        margin-right: 26px;
    }

    &__btn {
        cursor: pointer;
        display: inline-flex;
        padding: 14px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        background: linear-gradient(180deg, #3671E9 0%, #654DB3 100%);
        box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.15) inset, 0px 0px 30px 0px rgba(255, 255, 255, 0.05) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: white;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        ;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;


    }

    &__btn:hover {
        
        transition: 0.3s;
        padding: 12px 32px;
        background:linear-gradient(180deg, #654DB3 0%, #654DB3 100%);
    }
    &__link2 {
        display: none;
    }
    &__formobile {
        display: none;
    }
    &__burger {
        display: none;
    }
}

@media (max-width: 800px) {
    .header {
        z-index: 100000;
        &__nav {
            display: none;
        }
        &__link {
            &:not(:last-child) {
                margin-right: 0;
            }
    
        }
        &__link2 {
            display: block;
            margin-right: 30px !important;
    
    
        }
        &__formobile {
            display: flex;
        }
     
        
        & .burger {
            display: block;
            position: relative;
            width: 35px;
            border: 2px solid #FFF;
            border-radius: 10px;
            z-index: 1000;
            transition: 0.3s;
            height: 2px;


            &__nav {
                height: 100%;
            }

            &__divforabsoulte {
                position: relative;
            }

            &__link {
                position: relative;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: var(--white);
                flex: none;
                order: 1;
                flex-grow: 0;
                transition: 0.3s;
            

                &.canHover:hover {
                    color: var(--f);
                }
            }

            &__click {
                position: absolute;
                width: 50px;
                height: 50px;
                top: -25px;
                left: -8px;
                ;

            }

            // &__link:not(:last-child) {
            //     margin-bottom: 15px;
            // }
            &::before {
                position: absolute;
                content: "";
                left: -2px;
                top: -12px;
                width: 100%;
                border: 2px solid #FFF;
                border-radius: 10px;
                transition: 0.3s;
            }

            &::after {
                position: absolute;
                content: "";
                left: -2px;
                bottom: -12px;
                width: 100%;
                border: 2px solid #FFF;
                border-radius: 10px;
                transition: 0.3s;
            }

            &__menu {
                transition: 0.8s;
                position: absolute;
                left: 0;
                width: 100%;
                height: 50vh;
                background: rgba(57, 39, 112, 0.87);
                padding: 40px 0;
            }
        }

    }
    .active.burger {
        transform: rotate(45deg);

        &::after {
            transform: rotate(-90deg);
            bottom: -2px;
        }

        &::before {
            display: none;
        }

    }
}




@media (max-width: 600px) {
    .header__btn    {
        font-size: 12px;
        padding: 12px 18px;
    }
}

@media (max-width: 370px) {
    .header__link2   {
      display: none;
    }
}

@media (max-height: 500px) {
    .header {
        & .burger {
            &__menu {
                height: 80vh;
            }
        }
    }
}



