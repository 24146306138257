.home {
    background-color: black;
    position: relative;
}

.navbarToSticky {
    margin: 0 auto;
    position: sticky;
}

