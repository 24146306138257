.closedclub {
    padding: 150px 0;

    &__title {
        color: #FFF;
        font-family: 'Montserrat', sans-serif;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__desc {
        color: #CFCFCF;
        text-align: center;
        font-family: 'Outfit', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin: 10px 0 55px;
    }

    &__btn {
        cursor: pointer;
        border-radius: 32px;
        background: linear-gradient(180deg, #3671E9 0%, #654DB3 100%);
        color: #FFF;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        padding: 20px 43px;
    }

    &__btn:hover {
        transition: 0.3s;

        padding: 20px 41px;
        background: linear-gradient(180deg, #654DB3 0%, #654DB3 100%);
    }
}


@media (max-width: 700px) {
    .closedclub {
        &__title {
            text-align: center;
        }
    }
}


@media (max-width: 500px) {
    .closedclub {
        &__title {
            font-size: 35px;
        }

        &__desc {
            font-size: 16px;
        }
    }
}

@media (max-width: 300px) {
    .closedclub {
        &__title {
            font-size: 35px;
        }

        &__desc {
            font-size: 18px;
        }
    }
}