.hero {
    margin: 0 auto;
    background-color: black;
    position: relative;
    padding-bottom: 100px;
    overflow: hidden;
    padding-top: 100px;



    &__discount {
        max-width: 780px;
        height: 40px;
        z-index: 102;
        padding: 10px;
    }

    &__discount {
        z-index: 100;
        position: relative;
        margin-top: 96px;
        border-radius: 32px;
        background: rgba(255, 255, 255, 0.10);
    }

    &__15 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 139px;
        height: 32px;
        border-radius: 32px;
        background: var(--neutral-white, #FFF);
        color: #0D0D2B;
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        z-index: 1000;
    }

    &__bot {
        color: var(--neutral-white, #FFF);
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.16px;
    }

    &__title {
        color: var(--neutral-white, #FFF);
        font-family: 'Impact', sans-serif;
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 713px;
        margin-top: 80px;

    }

    &__desc {
        color: var(--neutral-grey-5, #E0E0E0);
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 155.556% */
        letter-spacing: 0.18px;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    &__content {
        position: relative;
        margin-bottom: 120px;
    }

    &__line2 {
        top: 17rem;
        right: 37rem;
        position: absolute;
        width: 120px;
        height: 997.881px;
        transform: rotate(-120deg);
        flex-shrink: 0;
        background: linear-gradient(186deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.04) 38.79%, rgba(255, 255, 255, 0.00) 100%);
    }

    &__line1 {
        position: absolute;
        z-index: 1;
        top: 4rem;
        left: 0;
        width: 120px;
        height: 1002.259px;
        transform: rotate(-120deg);
        flex-shrink: 0;
        background: linear-gradient(186deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.04) 38.79%, rgba(255, 255, 255, 0.00) 100%);
    }

    &__subscribe {
        cursor: pointer;
        display: flex;
        width: 378px;
        padding: 16px 16px 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 7px;
        border-radius: 32px;
        background: linear-gradient(131deg, #3C4CD4 0%, #654DB3 100%);
        box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.15) inset, 0px 0px 30px 0px rgba(255, 255, 255, 0.05) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: var(--neutral-white, #FFF);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        font-family: 'Montserrat', sans-serif;
    }
    &__subscribe:hover {
        transition: 0.3s;
        width: 376px;
       
        background:linear-gradient(180deg, #654DB3 0%, #654DB3 100%);
    }

    &__right {
        position: relative;
        align-items: flex-end;
    }

    &__right2 {
        display: none;
    }

    &__img {
        z-index: 10;
        width: 499.058px;
        height: 499.058px;


        & img {
            border-radius: 50%;
            background: rgba(217, 217, 217, 0.00);
            box-shadow: 0px 0px 10px 0px rgba(118, 6, 230, 0.725), 0px 0px 10px 0px rgba(118, 6, 230, 0.725), 0px 0px 15px 1px rgba(118, 6, 230, 0.725), 0px 0px 10px 0px rgba(118, 6, 230, 0.725), 0px 0px 10px 0px rgba(118, 6, 230, 0.725), 0px 0px 10px 0px rgba(118, 6, 230, 0.725);
            backdrop-filter: blur(10px);

        }
    }

    &__about1 {
        margin: 0 auto;
    }

    & .about {
        max-width: 1500px;
        margin: 0 auto;

        &__title {
            color: var(--neutral-white, #FFF);
            font-family: 'Montserrat', sans-serif;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;

        }

        &__desc {
            color: var(--neutral-grey-5, #E0E0E0);
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.16px;
            max-width: 277px;
        }
    }

}

.circle {
    &__right {
        position: absolute;
        top: 0;
        right: 0;
        width: 100rem;
        height: 100rem;

        &__img {
            width: 100%;
            height: 100%;

        }
    }



    &__left {
        position: absolute;
        top: 0;
        left: 0;

        width: 66rem;
        height: 56rem;

        & img {
            width: 100%;
            height: 100%;
            z-index: -1;

        }
    }
}


.divforsvg {
    position: relative;
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 50%;

}

.divforsvg:not(:last-child) {
    margin-right: 24px;
}




.small {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

}



@media (max-width: 1250px) {
    .hero {
        &__discount {
            margin: 30px auto 50px;
        }

        &__content {
            grid-template-columns: 1fr;
        }

        &__title {
            text-align: center;
        }

        &__desc {
            width: 50%;
            text-align: center;
        }

        &__left {
            align-items: center;
        }

        &__right {
            display: none;
        }

        &__right2 {
            display: flex;
            align-items: center;
        }

        &__about {
            grid-template-columns: 1fr;
            row-gap: 30px;
        }
    }

    .about {
        &__Right {
            width: 300px;
        }
    }

}

@media (max-width: 700px) {
    .hero {
        &__discount {
            flex-direction: column;
            height: auto;
        }

        &__bot {
            text-align: center;
        }

        &__img {
            width: 60%;
            height: 60%;

            & img {
                width: 100%;
            }
        }
    }

    .about {
        &__Right {
            width: 300px;
        }
    }

}

@media (max-width: 500px) {
    .hero {
        &__title {
            font-size: 44px;
        }
        &__desc {
            width: 88%;
        }
        &  .about {
            &__title {
                font-size: 23px;
            }
            &__Right {
                width: 250px;
            }
            &__desc {
                font-size: 14px;
            }
        }
    }

}

@media (max-width: 400px) {
    .hero {
        &__subscribe {
            width: 90%;
        }

       &__title {
        font-size: 37px;
       }
       &__desc {
        font-size: 16px;
       }
       &__about {
        padding: 0 10px;
       }

       & .about {
        &__title {
            font-size: 20px;
        }
        &__desc {
            font-size: 13px;
        }
       }
    }

   
}


@media (max-width: 350px) {
    .hero {
    
        &__title {
            font-size: 33px;
        }
        &  .about {
            &__Right{
                width: 170px
            }
        }
    }

   
}